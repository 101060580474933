import React, { useEffect, useState } from 'react'
import { BuildingStorefrontIcon, CheckBadgeIcon, PhoneArrowUpRightIcon } from "@heroicons/react/20/solid";

import './Home.css'
import { anchorScroll } from '../../helpers/helperFunctions';

// Import pics
import dentistFacts from '../../assets/dentistFacts.jpg'
import dentistLove from '../../assets/dentistLove.jpg'
import raduSusana from '../../assets/raduSusana.jpg'

// Import components
import ThreeBlocks from '../../components/ThreeBlocks'
import RoleTile from '../../components/RoleTile'
import FeatureBlock from '../../components/FeatureBlock';
import DetailsForm from '../../components/DetailsForm';
import ContactForm from '../../components/ContactForm';

function Home(props) {
    const [toggleRoleDetails, setToggleRoleDetails] = useState(null)
    const { isEnglish } = props

    useEffect(() => {
        if (!localStorage.getItem('home') && window.innerWidth > 768) {
            //Effects
            const bounce = new IntersectionObserver(entries => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add('bounce');
                    }
                });
            });

            bounce.observe(document.querySelector('.heroTitle'));
            bounce.observe(document.querySelector('.heroSubtitle'));
            bounce.observe(document.querySelector('.heroButton'))
            localStorage.setItem('home', true)
        }
    }, [])


    return (
        <div className='overflow-x-hidden'>

            {/* Home hero with separator */}
            <div className='heroContainer'>
                {window.innerWidth > 768 &&
                    <div className='heroTextContainer'>
                        <h2 className='heroSubtitle text-lg md:text-2xl max-w-2xl pb-3'>DENTISTES À DÉOLS</h2>
                        {/* ET MÉDECINE GÉNÉRALE */}
                        <h1 className='heroTitle mb-1 md:mb-3 md:text-5xl md:mb-1 leading-tight text-3xl max-w-2xl pb-3'>VOTRE CENTRE DE SANTÉ AU COEUR DE L'INDRE</h1>
                        <h3 className='heroSubtitle text-lg md:text-2xl max-w-xl pb-4'>Toutes les soins dentaires réunis dans un même lieu, pour toute la famille</h3>
                        {/* et de médecine générale */}
                        <div className='flex gap-4 md:text-lg'>
                            <a href="/cabinet" className="">
                                <button className='heroButton flex items-center inline-block mt-3 rounded-full bg-black mt-10 px-8 py-2 font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'>

                                    Découvrir le cabinet
                                    <BuildingStorefrontIcon className='w-4 h-4 ml-3' />
                                </button>
                            </a>
                            <a href="/soins" className="">
                                <button className='heroButton flex items-center inline-block mt-3 rounded-full bg-black mt-10 px-8 py-2 font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'>

                                    Les soins proposés
                                    <CheckBadgeIcon className='w-4 h-4 ml-3' />
                                </button>
                            </a>
                        </div>
                    </div>
                }
            </div>
            {window.innerWidth > 768 &&
                <div class="custom-shape-divider-bottom-1731245909">
                    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                        <path d="M600,112.77C268.63,112.77,0,65.52,0,7.23V120H1200V7.23C1200,65.52,931.37,112.77,600,112.77Z" class="shape-fill"></path>
                    </svg>
                </div>
            }
            {/* <div className="custom-shape-divider-bottom-1731246982">
                <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                    <path d="M600,112.77C268.63,112.77,0,65.52,0,7.23V120H1200V7.23C1200,65.52,931.37,112.77,600,112.77Z" className="shape-fill"></path>
                </svg>
            </div> */}

            {window.innerWidth < 768 &&
                <div className='px-6 md:px-20 my-10'>
                    <h1 className='heroTitle mb-0 leading-tight text-2xl font-bold text-center max-w-2xl pb-2'>VOTRE CENTRE DE SANTÉ AU COEUR DE L'INDRE</h1>
                    <div className='flex flex-col justify-center items-center'>
                        <a href="/cabinet" className="">
                            <button className='heroButton text-sm flex items-center inline-block mt-3 rounded-full bg-black mt-10 px-10 py-3 font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'>

                                Découvrir le cabinet
                                <BuildingStorefrontIcon className='w-5 h-5 ml-3' />
                            </button>
                        </a>
                        <a href="tel:+33254351509" className="">
                            <button className='heroButton text-sm flex items-center inline-block mt-3 rounded-full bg-black mt-10 px-10 py-3 font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'>

                                Prendre rendez-vous
                                <PhoneArrowUpRightIcon className='w-5 h-5 ml-3' />
                            </button>
                        </a>
                    </div>
                </div>}

            <div className='px-5 md:px-28 mt-16 md:mt-20 text-center'>
                <h3 className="text-md md:text-xl font-semibold leading-7 text-indigo-600">DENTISTES À DÉOLS</h3>
                {/* ET MÉDECINE GÉNÉRALE  */}
                <h2 className="mb-2 md:mb-8 md:mt-1 text-3xl font-bold md:text-4xl tracking-tight text-gray-900">
                    LE CENTRE DE SANTÉ OASIS
                </h2>
                <FeatureBlock
                    text="Nos dentistes vous accueillent dans un cadre moderne à 5 minutes du centre de Châteauroux. Soins dentaires généraux ou esthétiques, parodontologie, implantologie ou bilans bucco-dentaires - prenez rendez-vous facilement en nous contactant par téléphone au 02 54 35 15 09."
                    // et médecin généraliste | de médecine générale
                    title="Une offre de soins complète"
                    button="Voir les soins"
                    link='/soins'
                    img={dentistLove}
                    offset={false}
                />
                <FeatureBlock
                    text="Tous nos praticiens sont hautement qualifiés et vous accueilleront avec courtoisie et un vrai sens de l'écoute. Vous souhaitez en savoir plus sur nos chirurgiens-dentistes et notre équipe d'assistantes et de secrétaires médicales? Découvrez notre équipe."
                    // et médecins
                    title="Une équipe engagée"
                    button="Découvrir l'équipe"
                    link='/equipe'
                    img={raduSusana}
                    offset={true}
                />
            </div>

            {/* Three items about being a doctor in France */}
            <div className='px-0 md:px-12 mt-16 md:mt-20'>
                <ThreeBlocks isEnglish={isEnglish} />
            </div>

            {/* Our disciplines of medicine */}
            <div className='px-4 md:px-32 mt-32 md:mt-44'>
                <div className="relative bg-white py-8 md:pt-14 md:pb-20 px-4 md:px-8 rounded-xl shadow-xl text-center" id="careers">
                    <div className='heroPatternStars absolute bottom-0 left-0 -translate-x-24 translate-y-8 md:-translate-x-12 md:translate-y-12 -z-10' />
                    <div className='heroPatternStarsH absolute top-0 right-0 translate-x-32 -translate-y-8 md:translate-x-12 md:-translate-y-12 -z-10' />
                    <h3 className="text-md md:text-xl font-semibold leading-7 text-indigo-600">NOS DOMAINES D'EXPERTISE</h3>
                    <h2 className="mt-0 md:mt-1 text-3xl md:text-4xl font-bold tracking-tight text-gray-900">
                        LES SOINS DENTAIRES
                    </h2>
                    {/* ET MÉDECINE GÉNÉRALE */}
                    <div className='md:flex w-full md:gap-4 mt-4 md:mt-0'>
                        <div className='mt-0 md:w-1/2 md:mt-6 grid grid-cols-& auto-rows-max gap-y-3 md:gap-y-0'>
                            <RoleTile
                                anchorScroll={anchorScroll}
                                toggleIndex={1}
                                toggleRoleDetails={toggleRoleDetails}
                                setToggleRoleDetails={setToggleRoleDetails}
                                title='Dentisterie Générale'
                                facts='Nous vous accueillons pour tous types de soins: contrôles de routine, traitements de caries, détartrages, extractions, soins esthétiques ainsi que prothèses dentaires (couronnes, bridges, appareils amovibles).'
                                factsImg={dentistFacts}
                            /><RoleTile
                                anchorScroll={anchorScroll}
                                toggleIndex={2}
                                toggleRoleDetails={toggleRoleDetails}
                                setToggleRoleDetails={setToggleRoleDetails}
                                title='Endodontie'
                                facts='Nous vous accueillons pour tous types de soins: traitements de canal, reprises de traitements endodontiques, gestion des infections et inflammations pulpaires et suivi des soins dentaires conservateurs.'
                                factsImg={dentistFacts}
                            />
                        </div>
                        <div className='mt-0 md:w-1/2 md:mt-6 grid grid-cols-& auto-rows-max'>
                            <RoleTile
                                anchorScroll={anchorScroll}
                                toggleIndex={3}
                                toggleRoleDetails={toggleRoleDetails}
                                setToggleRoleDetails={setToggleRoleDetails}
                                title='Parodontologie'
                                facts='Nous vous accueillons pour tous types de soins: diagnostics, traitement des maladies parodontales, détartrages et surfaçages radiculaires, suivis post-traitement ou conseils en hygiène bucco-dentaire.'
                                factsImg={dentistFacts}
                            />
                            <RoleTile
                                anchorScroll={anchorScroll}
                                toggleIndex={4}
                                toggleRoleDetails={toggleRoleDetails}
                                setToggleRoleDetails={setToggleRoleDetails}
                                title='Implantologie'
                                facts="Nous vous accueillons pour tous types de soins: évaluation et pose d'implants dentaires, planification chirurgicale, greffes osseuses, suivi post-opératoire et conseils en entretien d'implants."
                                factsImg={dentistFacts}
                            />
                            {/* <RoleTile
                                anchorScroll={anchorScroll}
                                toggleIndex={5}
                                toggleRoleDetails={toggleRoleDetails}
                                setToggleRoleDetails={setToggleRoleDetails}
                                title='Médecine Générale'
                                facts="Nous vous accueillons pour tous types de soins: bilans de santé, suivis réguliers, soins d'urgence, conseils en prévention et vaccinations"
                                factsImg={dentistFacts}
                            /> */}
                        </div>
                    </div>
                </div>
            </div>

            {/* Map container with separator */}
            <div className='mt-32 md:mt-48'>
                <ContactForm anchorScroll={anchorScroll} />
            </div>
            <div className="custom-shape-divider-top-1725819627">
                <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                    <path d="M1200 120L0 16.48 0 0 1200 0 1200 120z" className="shape-fill"></path>
                </svg>
            </div>

            {/* Contact and form */}
            <div className='px-2 md:px-6 mt-14 md:mt-6'>
                <DetailsForm />
            </div>
        </div>
    )
}

export default Home