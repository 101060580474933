import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/free-mode";
import './ActiveSlider.css'

import {
    ArrowLeftCircleIcon,
    ArrowRightCircleIcon,
} from '@heroicons/react/20/solid'

import { FreeMode, Pagination } from "swiper/modules";
import { useEffect, useRef } from "react";

function ActiveSlider(props) {
    const sliderRef = useRef()

    useEffect(() => {
        sliderRef.current?.slideTo(props.scrolledLocale)
    }, [props.scrolledLocale])

    return (
        <div className={"flex items-center justify-center"}>
            {window.innerWidth > 768 &&
                <button onClick={() => {
                    if (props.setScrolledLocale) {
                        props.setScrolledLocale(Math.max(props.scrolledLocale - 1, 0))
                    }
                    else {
                        props.setScrolledLocaleMain(Math.max(props.scrolledLocaleMain - 1, 0))
                        sliderRef.current?.slidePrev()
                    }
                }}><ArrowLeftCircleIcon aria-hidden="true" style={(props.scrolledLocale === 0 || props.scrolledLocaleMain === 0) ? { opacity: '0.5', cursor: 'auto' } : {}} className={"mr-5 h-10 w-10 flex-shrink-0 text-gray-900"} /></button>
            }
            <Swiper
                onSwiper={it => (sliderRef.current = it)}
                breakpoints={{
                    500: {
                        slidesPerView: 1,
                        spaceBetween: 20,
                    },
                    780: {
                        slidesPerView: props.slides + 1,
                        spaceBetween: 20,
                    },
                    768: {
                        slidesPerView: props.slides + 1,
                        spaceBetween: 30
                    },
                    1600: {
                        slidesPerView: props.slides + 2,
                        spaceBetween: 30
                    }
                }}
                freeMode={false}
                navigation={true}
                slidesPerView={props.slidesPerView || 1}
                pagination={{
                    clickable: false,
                }}
                modules={[FreeMode, Pagination]}
                className="max-w-[100%] lg:max-w-[100%]"
            >
                {props.data.map((item, index) => (
                    <SwiperSlide key={item.title}>
                        <div key={item.title} className={`flex mx-3 md:mx-0 flex-col h-full justify-between items-end bg-white p-4 md:p-6 rounded-xl ${props.type === 'fluid' && index === props.data.length - 1 ? 'mr-7' : ''} ${props.type === 'fluid' && index === 0 ? 'ml-7' : ''}`}>
                            <div>
                                {item.backgroundImage !== 'none' &&
                                    <div className="aspect-h-1 overflow-hidden rounded-lg">
                                        <img alt="" src={item.backgroundImage} className="object-cover object-center" />
                                    </div>
                                }
                                <div className={`${props.type === 'fluid' ? 'pt-6 pb-14' : 'py-6'} rounded-b-xl md:px-4`}>
                                    <h4 className="text-3xl leading-tight md:text-2xl text-start font-bold leading-7 text-gray-900">{item.title}</h4>
                                    <p className={`mt-5 md:mt-3 text-base text-start leading-7 text-gray-800`}>{item.text}</p>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>
                ))
                }
            </Swiper>
            {
                window.innerWidth > 768 &&
                <button onClick={() => {
                    if (props.setScrolledLocale) {
                        props.setScrolledLocale(Math.min(props.scrolledLocale + 1, props.data.length - 1))
                    }
                    else {
                        props.setScrolledLocaleMain(Math.min(props.scrolledLocaleMain + 1, 6))
                        sliderRef.current?.slideNext()
                    }
                }}><ArrowRightCircleIcon style={(props.scrolledLocale === 6 || props.scrolledLocaleMain === 6) ? { opacity: '0.5', cursor: 'auto' } : {}} aria-hidden="true" className="ml-5 h-10 w-10 flex-shrink-0 text-gray-900" /></button>
            }
        </div>
    );
}

export default ActiveSlider