import attente from '../assets/attente.jpg'

export default function NotFound(props) {
    const { isEnglish } = props
    return (
        <>
            <div className="grid grid-cols-1 h-1/2 grid-rows-[1fr,auto,1fr] bg-white lg:grid-cols-[max(50%,36rem),1fr]">
                <div className="mx-auto w-full max-w-7xl px-6 py-24 md:py-32 lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:px-8">
                    <div className="max-w-lg">
                        <p className="text-base font-semibold leading-8 text-indigo-600">404</p>
                        <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 md:text-5xl">{isEnglish ? 'Page not found' : 'Page introuvable'}</h1>
                        <p className="mt-6 text-base leading-7 text-gray-600">
                            {isEnglish ?
                                'Sorry, we couldn’t find the page you’re looking for.'
                                :
                                'Désolé, nous ne trouvons pas la page que vous avez demandée.'
                            }
                        </p>
                        <div className="mt-10">
                            <a href="/home" className="text-sm font-semibold leading-7 text-indigo-600">
                                <span aria-hidden="true">&larr;</span>
                                {isEnglish ?
                                    'Back to home'
                                    :
                                    "Retour à l'accueil"
                                }
                            </a>
                        </div>
                    </div>
                </div>
                <div className="hidden lg:static lg:col-start-2 lg:row-start-1 lg:row-end-4 lg:block object-bottom-left">
                    <img
                        alt=""
                        src={attente}
                        className=" inset-0 w-full h-full object-cover"
                    />
                </div>
            </div>
        </>
    )
}
