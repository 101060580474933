import { useEffect } from 'react'

export default function Legal(props) {
    const { setCurrentScreen } = props

    useEffect(() => {
        setCurrentScreen(null)
    }, [setCurrentScreen])

    return (
        <div className="overflow-x-hidden">
            {/* Home hero with separator */}
            <div className='heroContainerSmallLegal'>
                <div className='heroTextContainerSmall'>
                    <h1 className='heroTitle md:mb-3 md:text-4xl md:mb-1 leading-tight text-3xl pb-3'>MENTIONS LÉGALES</h1>
                    <h3 className='heroSubtitle text-base md:text-xl pb-4'>
                        Nos informations réglementaires
                    </h3>

                </div>
            </div>
            {window.innerWidth > 768 &&
                <div class="custom-shape-divider-bottom-1731245909">
                    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                        <path d="M600,112.77C268.63,112.77,0,65.52,0,7.23V120H1200V7.23C1200,65.52,931.37,112.77,600,112.77Z" class="shape-fill"></path>
                    </svg>
                </div>
            }

            <div className="px-6 pb-24 lg:px-8 mx-auto max-w-3xl text-base leading-7 text-gray-700">
                <p className="text-lg mt-10 sm:mt-16 font-semibold leading-7">Oasis Médicale</p>
                <p className="mt-2 text-sm md:text-md leading-6">
                    oasissante.fr est édité par l'association Oasis Médicale, Association Loi 1901 déclarée auprès la Préfecture de l'Indre.<br />
                    Numéro de RCS : 924 652 563<br />
                    Adresse : 27 rue Ferdinand Gigot 36130 Déols, FRANCE<br />
                    Directrice de publication : Angeline PORTRAIT<br />
                    Contact : Nous contacter via notre adresse mail
                    <a href={'mailto:contact@oasissante.fr'} className='font-semibold' alt=""> contact@oasissante.fr</a>
                    <br /><br />
                    L'hébergement du site est assuré par la société HEROKU Inc. dont le siège est situé :
                    512 2nd Street, Suite 200
                    San Francisco, CA 94107
                    USA

                </p>
                <p className="text-lg mt-10 font-semibold leading-7">Données personnelles</p>
                <p className="mt-2 text-sm md:text-md leading-6">
                    Toutes les informations que vous nous communiquez ne sont utilisées que dans le cadre de votre relation avec oasissante.fr. Elles proviennent de l’enregistrement volontaire de votre adresse e-mail et des réponses à notre formulaire de contact. Ces informations ne sont jamais revendues.
                </p>
                <p className="text-lg mt-10 font-semibold leading-7">Confidentialité</p>
                <p className="mt-2 text-sm md:text-md leading-6">
                    Toutes les informations fournies lors de vos visites sur oasissante.fr sont strictement confidentielles. L'association Oasis Médicale s’engage à respecter la confidentialité de vos données personnelles et à les traiter dans le respect de la loi Informatique et Libertés du 6 janvier 1978.
                </p>
                <p className="text-lg mt-10 font-semibold leading-7">Vie Privée</p>
                <p className="mt-2 text-sm md:text-md leading-6">
                    Les données personnelles sont utilisées par Oasis Médicale afin de pouvoir délivrer son service.<br /><br />
                    - Les noms, les emails et les numéros de téléphone afin de contacter les patients ayant pris contact via le formulaire et organiser une première consultation avec l'un de nos praticiens<br /><br />
                    - Les emails obtenus après inscription à notre newsletter afin d'informer les utilisateurs de nos actualités<br /><br />
                    ‍
                    En vertu de la loi Informatique et Libertés du 6 janvier 1978, vous disposez d’un droit d’accès, d’un droit d’opposition, d’un droit de rectification et/ou de suppression pour les renseignements vous concernant. Vous pouvez exercer ceux-ci en adressant votre demande (en indiquant vos adresse de courrier électronique, nom, prénom, adresse postale), via notre
                    <a href={'mailto:contact@oasissante.fr'} className='font-semibold' alt=""> email de contact </a>
                    ou par courrier postal à l’adresse suivante :
                    Asso. Oasis Médicale, 27 rue Ferdinand Gigot, 36130 Déols.
                </p>
            </div>
        </div>
    )
}
