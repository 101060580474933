import React, { useState } from 'react'
import ActiveSlider from '../../components/ActiveSlider'

// Images
import medge from '../../assets/med-generale.jpg'
import desthetique from '../../assets/d-esthetique.jpg'
import dimplants from '../../assets/d-implants.jpg'
import dprevention from '../../assets/d-prevention.jpg'
import dparo from '../../assets/d-paro.jpg'
import dendo from '../../assets/d-endo.jpg'
import dconservateur from '../../assets/d-conservateur.jpg'
import damovible from '../../assets/d-amovible.jpg'
import dcrowns from '../../assets/d-crowns.jpg'
import DetailsForm from '../../components/DetailsForm'

function Soins(props) {
    const [scrolledLocaleMain, setScrolledLocaleMain] = useState(0)

    return (
        <div className='overflow-x-hidden'>

            {/* Home hero with separator */}
            <div className='heroContainerSmallSoins'>
                <div className='heroTextContainerSmall'>
                    <h1 className='heroTitle md:mb-3 md:text-4xl md:mb-1 leading-tight text-3xl pb-3'>APPROCHE MULTIDISCIPLINAIRE</h1>
                    <h3 className='heroSubtitle text-base md:text-xl pb-4'>
                        Une gamme complète de soins dentaires à Déols
                    </h3>
                    {/* et de médecine générale */}

                </div>
            </div>
            {window.innerWidth > 768 &&
                <div class="custom-shape-divider-bottom-1731245909">
                    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                        <path d="M600,112.77C268.63,112.77,0,65.52,0,7.23V120H1200V7.23C1200,65.52,931.37,112.77,600,112.77Z" class="shape-fill"></path>
                    </svg>
                </div>
            }

            <div className='px-4 md:px-20 text-center mt-12 md:mt-16' >
                <h3 className="text-base md:text-xl font-semibold leading-7 text-indigo-600">DENTISTERIE & MÉDECINE GÉNÉRALE</h3>
                <h2 className="mt-0 md:mt-2 text-3xl md:text-3xl font-bold tracking-tight text-gray-900 mb-4 md:mb-12">
                    Toute notre offre de soins
                </h2>
            </div>

            <div className='px-4 md:px-20 mt-8' >
                <div className="py-8 md:py-10 px-2 md:px-10 text-center bg-gray-100 rounded-2xl shadow-xl">
                    <ActiveSlider
                        setScrolledLocaleMain={setScrolledLocaleMain}
                        scrolledLocaleMain={scrolledLocaleMain}
                        data={[
                            // {
                            //     title: 'Médecine Générale',
                            //     text: "La médecine générale couvre le diagnostic, la prévention et le traitement des maladies courantes, comme les infections et troubles chroniques. Elle inclut aussi le suivi médical, les vaccinations et la gestion des risques pour une prise en charge globale.",
                            //     backgroundImage: medge,
                            // },
                            {
                                title: 'Soins conservateurs',
                                text: "Les soins conservateurs comprennent le traitement des caries et des cavités dentaires. Ils visent à restaurer la structure et la fonction des dents endommagées, tout en préservant au maximum les tissus dentaires sains.",
                                backgroundImage: dconservateur,
                            },
                            {
                                title: 'Endodontie',
                                text: "L’endodontie traite les infections et inflammations de la pulpe dentaire, située à l’intérieur de la dent. Cette discipline inclut notamment les traitements de canal, permettant de préserver la dent tout en éliminant la douleur et traitant les infections.",
                                backgroundImage: dendo,
                            },
                            {
                                title: 'Couronnes & Bridges',
                                text: "Les couronnes et bridges sont des restaurations fixes spécialement conçues pour renforcer ou remplacer des dents abîmées ou manquantes. Ils offrent une solution fiable et durable pour restaurer la fonction et l’apparence naturelle de la dentition.",
                                backgroundImage: dcrowns,
                            },
                            {
                                title: 'Prévention',
                                text: "La prévention inclut des bilans bucco-dentaires réguliers et des conseils personnalisés d’hygiène buccale. Ces mesures visent à détecter précocement les problèmes dentaires et à préserver la santé des dents et des gencives sur le long terme.",
                                backgroundImage: dprevention,
                            },
                            {
                                title: 'Dentisterie esthétique',
                                text: "La dentisterie esthétique propose des traitements pour améliorer la couleur, la forme ou l’alignement des dents. Elle inclut le blanchiment, les facettes et autres techniques visant à sublimer le sourire tout en préservant la santé bucco-dentaire.",
                                backgroundImage: desthetique,
                            },
                            {
                                title: 'Implantologie',
                                text: "L’implantologie consiste à poser un implant en titane dans l’os pour remplacer une dent manquante. Cette méthode restaure la fonction masticatoire et l’esthétique du sourire, tout en préservant la santé des dents et des gencives environnantes.",
                                backgroundImage: dimplants,
                            },
                            {
                                title: 'Parodontologie',
                                text: "La parodontologie se concentre sur le diagnostic et le traitement des maladies affectant les gencives et l’os entourant les dents. Elle vise à préserver ou restaurer les structures de soutien dentaire, essentielles à la santé et à la stabilité des dents.",
                                backgroundImage: dparo,
                            },
                            {
                                title: 'Prothèses amovibles',
                                text: "Les prothèses amovibles sont des solutions polyvalentes et efficaces pour remplacer plusieurs dents manquantes. Faciles à retirer et à entretenir, elles permettent de rétablir la fonction masticatoire et de retrouver un sourire esthétique.",
                                backgroundImage: damovible,
                            }

                        ]}
                        arrows={true}
                        slides={2}
                    />
                </div>
            </div>

            <div className='px-4 md:px-20 mt-20 md:mt-28' >
                <div className="mx-auto lg:flex">
                    <div className="mx-auto lg:mx-0 lg:min-w-full lg:max-w-none">
                        <div className="px-2 md:px-12 md:pb-8">
                            <h3 className="aboutText1 text-md md:text-xl font-semibold leading-7 text-indigo-600">POURQUOI VENIR CONSULTER?</h3>
                            <h2 className="mt-0 md:mt-2 aboutText2 text-3xl font-bold tracking-tight text-gray-900 md:text-3xl">Des soins de qualité, dans un cadre chaleureux</h2>

                            <h4 className='mt-6 mb-2 text-base md:text-lg leading-7 font-semibold'>
                                Pourquoi choisir le centre Oasis Santé à Déols, dans l’Indre ?
                            </h4>
                            <p className="aboutText3 text-base md:text-lg leading-7 text-gray-800">
                                Vous habitez Déols ou ses environs et cherchez un cabinet médical de confiance? Chez Oasis Santé, nous mettons tout en œuvre pour que chaque patient se sente unique et écouté. Ici, pas de soins standardisés : notre équipe expérimentée s’adapte à vos besoins, vous écoute et vous conseille pour que vous receviez les soins dont vous avez vraiment besoin.
                            </p>
                            <h4 className='mt-6 mb-2 text-base md:text-lg leading-7 font-semibold'>
                                Une prise en charge globale pour rester en bonne santé à tout âge
                            </h4>
                            <p className="aboutText3 text-base md:text-lg leading-7 text-gray-800">
                                Les soins, en particulier dentaires, peuvent être une source d’inquiétude et nous en sommes conscients. Que ce soit pour un bilan, une restauration, une urgence ou des traitements plus lourds, notre équipe est là pour vous accompagner et définir un plan de traitement adapté à votre situation. Votre santé et votre satisfaction sont nos priorités.
                            </p>
                            <h4 className='mt-6 mb-2 text-base md:text-lg leading-7 font-semibold'>
                                Un cabinet moderne et accueillant
                            </h4>
                            <p className="aboutText3 text-base md:text-lg leading-7 text-gray-800">
                                Notre cabinet à Déols est équipé des dernières technologies pour garantir des soins de qualité, en toute sécurité. Chaque détail a été pensé pour vous offrir une expérience agréable : règles d'hygiène, équipements modernes et cadre rassurant. Que vous veniez nous voir pour de la parodontologie, un bilan bucco-dentaire ou un traitement de carie, nous ferons tout notre possible pour rendre votre visite agréable.
                            </p>
                            <h4 className='mt-6 mb-2 text-base md:text-lg leading-7 font-semibold'>
                                Des praticiens pour chaque besoin
                            </h4>
                            <p className="aboutText3 text-base md:text-lg leading-7 text-gray-800">
                                Chez Oasis Santé, nous couvrons une large gamme de soins, allant du détartrage aux traitements des caries, en passant par l'endodontie, les implants et les prothèses fixes ou amovibles.
                            </p>
                            <h4 className='mt-6 mb-2 text-base md:text-lg leading-7 font-semibold'>
                                Une prise en charge rapide pour les urgences dentaires

                            </h4>
                            <p className="aboutText3 text-base md:text-lg leading-7 text-gray-800">
                                Les douleurs dentaires ne peuvent souvent pas attendre. C’est pourquoi nous offrons un service d’urgence pour vous soulager en cas de situation critique comme un abcès ou une douleur aiguë. Appelez-nous!
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            {/* Contact and form */}
            <div className='px-2 md:px-6 mt-14 md:mt-6'>
                <DetailsForm />
            </div>
        </div>
    )
}

export default Soins