import ActiveSlider from '../components/ActiveSlider'

export default function ThreeBlocks(props) {

    const features = [
        {
            title: 'Une expertise médicale riche',
            text: "Une équipe de praticiens expérimentés, compétents et à votre écoute. Nous mettons notre savoir-faire et notre énergie à votre service pour vous accompagner au mieux dans votre santé. Votre bien-être est notre priorité.",
        },
        {
            title: 'Les techniques les plus modernes',
            text: "Nous utilisons des équipements de pointe et les technologies les plus avancées pour vous garantir des soins d'une grande qualité. Chaque détail compte pour vous offrir une prise en charge globale et adaptée à vos besoins.",
        },
        {
            title: "Un cabinet et des soins de proximité",
            text: "Avec un ancrage local fort, nous nous engageons pour renforcer l'offre de soins de notre territoire. Des créneaux d'urgence sont disponibles au cabinet et nous prévoyons d'accueillir de nouveaux praticiens dans un futur proche.",
        },
    ]
    return (
        <div className="bg-white">
            <div className="relative isolate overflow-hidden pt-12 md:pt-0 shadow-2xl rounded-2xl md:rounded-3xl md:px-16 lg:flex lg:gap-x-20" style={{ backgroundColor: '#231A2E' }}>
                <svg
                    viewBox="0 0 1024 1024"
                    aria-hidden="true"
                    className="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-y-1/2 [mask-image:radial-gradient(closest-side,white,transparent)] md:left-full md:-ml-80 lg:left-1/2 lg:ml-0 lg:-translate-x-1/2 lg:translate-y-0"
                >
                    <circle r={512} cx={512} cy={512} fill="url(#759c1415-0410-454c-8f7c-9a820de03641)" fillOpacity="0.7" />
                    <defs>
                        <radialGradient id="759c1415-0410-454c-8f7c-9a820de03641">
                            <stop stopColor="#7775D6" />
                            <stop offset={1} stopColor="#E935C1" />
                        </radialGradient>
                    </defs>
                </svg>
                <div className="mx-auto text-center lg:mx-0 lg:flex-auto lg:text-left pt-12 pb-32 md:pt-24 md:pb-32">
                    <h2 className="text-3xl px-8 md:px-0 leading-snug text-start md:text-4xl font-semibold md:font-bold text-white">
                        Notre savoir-faire, au service de votre santé
                    </h2>
                    <div className="careerBlock mt-12">
                        {window.innerWidth > 768 ?
                            <dl className="grid max-w-xl grid-cols-1 gap-x-14 md:gap-x-12 gap-y-6 md:gap-y-10 lg:max-w-none lg:grid-cols-3 lg:gap-y-16">
                                {features.map((feature) => (
                                    <div key={feature.name} className="px-6 py-8 md:px-8 md:py-10 md:relative flex flex-col justify-between bg-gray-100 rounded-2xl items-start">
                                        <h4 className="text-xl md:text-3xl font-semibold text-gray-900">{feature.title}</h4>
                                        <dd className="mt-2 md:mt-4 text-sm md:text-base md:leading-7 leading-6 text-gray-800">{feature.text}</dd>
                                    </div>
                                ))}
                            </dl>
                            :
                            <ActiveSlider
                                data={features}
                                slides={1}
                                slidesPerView={1.15}
                                type="fluid"
                            />
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
