import implantShow from '../assets/implantShow.jpg'
import attente from '../assets/attente.jpg'
import teamAbout from '../assets/deols.jpg'
import chaiseDeols from '../assets/chaiseDeols.jpg'
import susanaChair from '../assets/susanaChair.jpg'
import raduChair from '../assets/raduChair.jpg'

export default function AboutHero(props) {
    return (
        <div className="overflow-hidden bg-white py-10">
            <div className="mx-auto max-w-7xl lg:flex">
                <div className="mx-auto grid max-w-xl grid-cols-1 gap-x-12 gap-y-16 lg:mx-0 lg:min-w-full lg:max-w-none lg:flex-none lg:gap-y-8">
                    <div className="px-6 md:px-12 lg:col-end-1 lg:w-full lg:max-w-2xl md:pb-8">
                        <h3 className="aboutText1 text-lg font-semibold leading-7 text-indigo-600">LE CENTRE OASIS</h3>
                        <h2 className="mt-0 md:mt-2 aboutText2 text-3xl font-bold tracking-tight text-gray-900">Un cabinet à taille humaine, en multi-disciplinaire </h2>
                        <p className="aboutText3 mt-6 text-base md:text-lg leading-7 text-gray-800">
                            Le Centre de Santé Oasis de Déols regroupe plusieurs dentistes pour des soins coordonnés de qualité, adaptés aux besoins de chaque patient. Notre structure permet de faciliter les consultations, en rendant simple le passage d’une spécialité dentaire à une autre dans un même lieu.
                        </p>
                        {/* et un médecin généraliste  //   une consultation médicale */}
                        <p className="aboutText4 mt-2 md:mt-4 text-base md:text-lg leading-7 text-gray-800">
                            Notre équipe met un point d’honneur à accueillir chaque patient avec bienveillance et professionnalisme. Les praticiens, assistés par un personnel qualifié, se consacrent entièrement aux soins et travaillent ensemble pour assurer une prise en charge complète, claire et adaptée.
                        </p>
                        <p className="aboutText5 mt-2 md:mt-4 text-base md:text-lg leading-7 text-gray-800">
                            Notre philosophie est de simplifier votre accès aux soins : nous pratiquons le tiers payant (obligatoire ou intégral suivant les cas) et restons attentifs aux retours de nos patients pour constamment améliorer notre pratique et nos services.
                        </p>
                        <div className="aboutText5 mt-4 flex">
                            <div
                                onClick={() => props.anchorScroll('#contact-form')}
                                className="rounded-md cursor-pointer bg-gray-900 px-3.5 py-2.5 text-sm md:text-md font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >
                                Voir plus d'informations <span aria-hidden="true">&rarr;</span>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-wrap items-start justify-end gap-6 md:gap-8 lg:contents">
                        <div className="aboutImg1 w-0 flex-auto lg:ml-auto lg:w-auto lg:flex-none lg:self-end">
                            <img
                                alt=""
                                src={teamAbout}
                                className="aspect-[7/5] w-[20rem] md:w-[37rem] max-w-none rounded-2xl bg-gray-50 object-cover object-left-bottom"
                            />
                        </div>
                        <div className="aboutImg2 contents lg:col-span-2 lg:col-end-2 lg:ml-auto lg:flex lg:w-[37rem] lg:items-start lg:justify-end lg:gap-x-8">
                            <div className="order-first -ml-16 md:-ml-36 md:ml-0 flex w-64 flex-none justify-end self-end lg:w-auto">
                                <img
                                    alt=""
                                    src={susanaChair}
                                    className="aspect-[4/3] w-[16rem] md:w-[24rem] max-w-none flex-none rounded-2xl bg-gray-50 object-cover object-right-center"
                                />
                            </div>
                            <div className="aboutImg3 translate-x-10 hidden md:block md:translate-x-4 md:translate-x-0 flex w-96 flex-auto justify-end lg:w-auto lg:flex-none">
                                <img
                                    alt=""
                                    src={implantShow}
                                    className="aspect-[7/5] w-[20rem] md:w-[37rem] max-w-none -translate-x-20 md:translate-x-0 flex-none rounded-2xl bg-gray-50 object-cover"
                                />
                            </div>
                            <div className="aboutImg4 -translate-x-10 md:w-0 md:flex-auto lg:w-auto lg:flex-none">
                                <img
                                    alt=""
                                    src={raduChair}
                                    className="aspect-[4/3] md:translate-x-16 w-[20rem] md:w-[24rem] max-w-none rounded-2xl bg-gray-50 object-cover"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
