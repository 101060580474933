import './TeamFeature.css'

export default function TeamFeature(props) {
    return (
        <div className='bg-white relative md:mx-12 px-6 md:px-10 py-12 md:py-20 rounded-xl shadow-xl border-t-2 border-gray-100'>
            <div className="mx-auto grid max-w-7xl grid-cols-1 gap-x-8 md:gap-y-20 md:px-8 xl:grid-cols-5">
                <div className="max-w-2xl xl:col-span-2">
                    <h4 className="text-2xl font-bold tracking-tight text-gray-900 md:text-2xl">{props.title}</h4>
                    <p className="mt-2 md:mt-4 text-md md:text-md leading-6 text-gray-600">
                        {props.pitch}
                    </p>
                </div>
                <ul role="list" className="space-y-8 md:space-y-12 divide-y divide-gray-200 xl:col-span-3">
                    {props.people.map((person) => (
                        <li key={person.name} className="flex flex-col gap-4 md:gap-10 pt-10 md:pt-12 md:flex-row">
                            <img alt="" src={person.imageUrl} className="aspect-[4/5] w-40 md:w-52 flex-none rounded-2xl object-cover" />
                            <div className="max-w-xl flex-auto">
                                <h3 className="text-2xl font-semibold leading-8 tracking-tight text-gray-900">{person.name}</h3>
                                <p className="text-md md:text-md leading-6 text-gray-600">{person.role}</p>
                                <p className="mt-4 text-md md:leading-7 leading-6 text-gray-800">{person.bio}</p>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    )
}
