import React, { useEffect, useState } from 'react'
import { Link } from "react-router-dom";
import 'animate.css';
import './Navbar.css'
import { useNavigate } from "react-router-dom";
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'
import { Bars3Icon, PhoneArrowUpRightIcon, XMarkIcon } from '@heroicons/react/20/solid'

import logo from '../../assets/logo.png'
import logoIcon from '../../assets/logoIcon.png'


function Navbar(props) {
    const navigate = useNavigate();
    const [burgerOpen, setBurgerOpen] = useState(false)

    useEffect(() => {
        if (burgerOpen) {
            const rotateIn = new IntersectionObserver(entries => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add('rotateIn');
                    }
                });
            });

            //Effects
            rotateIn.observe(document.querySelector('.burgerMenu'))
        }
    }, [burgerOpen])

    return (
        <div className='navbarContainer'>
            <div className='bandeau'>
                Centre de Santé Oasis - 27 rue Ferdinand Gigot 36130 Déols
            </div>
            <div className='navbar'>
                {window.innerWidth <= 768 &&
                    <div className='navbarBurgerContainer'>
                        <Menu as="div" className="data-[active]:flipInX relative inline-block text-right">
                            <MenuButton
                                className='text-white data-[active]:text-indigo-600 flex px-2 md:px-4 pt-1 pb-1 items-center text-gray-900 hover:text-gray-600 focus:outline-none'
                                onClick={() => setBurgerOpen(!burgerOpen)}
                            >
                                <Bars3Icon aria-hidden="true" className={`${burgerOpen && 'burgerMenu'} h-9 w-9`} />
                            </MenuButton>

                            <MenuItems
                                transition
                                className="fixed top-0 left-0 h-full w-full z-10 shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-300 data-[enter]:slide-out data-[leave]:slide-in"
                                style={{ backgroundColor: '#231a2e' }}
                            >
                                <div
                                    className='py-8 px-0.5 z-20 flex w-full justify-end'
                                    onClick={() => {
                                        setBurgerOpen(false)
                                    }}
                                >
                                    <MenuItem
                                        className='text-white flex px-2 pt-1 pb-1 items-center text-gray-900 hover:text-gray-600 focus:outline-none'
                                    >
                                        <XMarkIcon aria-hidden="true" className="h-12 w-12" />
                                    </MenuItem>
                                </div>
                                <div>
                                    <MenuItem>
                                        <div
                                            onClick={() => {
                                                setBurgerOpen(false)
                                                navigate('/cabinet')
                                            }}
                                            className="block flex font-bold items-center cursor-pointer pl-8 pr-14 py-5 text-sm text-white data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
                                        >
                                            <p className='mt-1 text-lg'>LE CABINET</p>
                                        </div>
                                    </MenuItem>
                                </div>
                                <div>
                                    <MenuItem>
                                        <div
                                            onClick={() => {
                                                setBurgerOpen(false)
                                                navigate('/soins')
                                            }}
                                            className="block flex font-bold items-center cursor-pointer pl-8 pr-14 py-5 text-sm text-white w-full data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
                                        >
                                            <p className='mt-1 text-lg'>LES SOINS</p>
                                        </div>
                                    </MenuItem>
                                </div>
                                <div>

                                    <MenuItem>
                                        <div
                                            onClick={() => {
                                                setBurgerOpen(false)
                                                navigate('/equipe')
                                            }}
                                            className="block flex font-bold items-center cursor-pointer pl-8 pr-14 py-5 text-sm text-white data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
                                        >
                                            <p className='mt-1 text-lg'>NOTRE ÉQUIPE</p>
                                        </div>
                                    </MenuItem>
                                </div>
                                <div>
                                    <MenuItem>
                                        <div
                                            onClick={() => {
                                                setBurgerOpen(false)
                                                navigate('/contact')
                                            }}
                                            className="block flex font-bold items-center cursor-pointer pl-8 pr-14 py-5 text-sm text-white data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
                                        >
                                            <p className='mt-1 text-lg'>CONTACT</p>
                                        </div>
                                    </MenuItem>
                                </div>
                                <div>
                                    <MenuItem>
                                        <div className='flex py-10 px-6 text-lg justify-between gap-x-2 items-center text-white'>
                                            Prenez RDV:

                                            <a href="tel:+33254351509">
                                                <button className='flex items-center rounded-md bg-gray-100 py-2 px-4 text-gray-900 text-xl font-semibold shadow-lg hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2'>
                                                    02 54 35 15 09
                                                    <PhoneArrowUpRightIcon aria-hidden="true" className="h-5 w-5 ml-3" />
                                                </button>
                                            </a>
                                        </div>
                                    </MenuItem>
                                </div>
                            </MenuItems>
                        </Menu>
                    </div>
                }
                <div className='navbarLogoContainer'>
                    <Link onClick={() => props.setCurrentScreen('/')} className='link' to={'/'}><img loading="lazy" className='logo' src={logo} alt='' /></Link>
                </div>
                {window.innerWidth > 768 &&
                    <>
                        <div className='navbarNavContainer flex flex-row-reverse md:flex-row'>
                            {props.currentScreen === '/cabinet' ?
                                <div className='navTitle'>
                                    <Link onClick={() => props.setCurrentScreen('/cabinet')} className='link' to={'/cabinet'}>LE CABINET</Link>
                                    <span className='underline' />
                                </div>
                                :
                                <div className="hover-underline-animation">
                                    <Link onClick={() => props.setCurrentScreen('/cabinet')} className='link' to={'/cabinet'}>LE CABINET</Link>
                                </div>
                            }
                            {props.currentScreen === '/soins' ?
                                <div className='navTitle'>
                                    <Link onClick={() => props.setCurrentScreen('/soins')} className='link' to={'/soins'}>LES SOINS</Link>
                                    <span className='underline' />
                                </div>
                                :
                                <div className="hover-underline-animation">
                                    <Link onClick={() => props.setCurrentScreen('/soins')} className='link' to={'/soins'}>LES SOINS</Link>
                                </div>
                            }
                            {
                                props.currentScreen === '/equipe' ?
                                    <div className='navTitle'>
                                        <Link onClick={() => props.setCurrentScreen('/equipe')} className='link' to={'/equipe'}>NOTRE ÉQUIPE</Link>
                                        <span className='underline' />
                                    </div>
                                    :
                                    <div className="hover-underline-animation">
                                        <Link onClick={() => props.setCurrentScreen('/equipe')} className='link' to={'/equipe'}>NOTRE ÉQUIPE</Link>
                                    </div>
                            }
                            {
                                props.currentScreen === '/contact' ?
                                    <div className='navTitle'>
                                        <Link onClick={() => props.setCurrentScreen('/contact')} className='link' to={'/contact'}>CONTACT</Link>
                                        <span className='underline' />
                                    </div>
                                    :
                                    <div className="hover-underline-animation">
                                        <Link onClick={() => props.setCurrentScreen('/contact')} className='link' to={'/contact'}>CONTACT</Link>
                                    </div>
                            }
                        </div>
                        <div className='navbarButtonsContainer'>
                            <div className='flex gap-x-2 justify-end items-center'>
                                <p className='text-base text-white font-semibold mb-0.5'>Prenez RDV au:</p>
                                <a href="tel:+33254351509" className='flex items-center inline-block rounded-md bg-black p-1 md:px-4 md:py-2 text-gray-900 text-white cursor-pointer text-sm md:text-base font-semibold shadow-lg hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2'>
                                    02 54 35 15 09
                                </a>
                            </div>
                        </div>
                    </>
                }
            </div>
        </div>
    )
}

export default Navbar