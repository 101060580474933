import React, { useEffect } from 'react'
import { anchorScroll } from '../../helpers/helperFunctions';

// Import components
import AboutHero from '../../components/AboutHero';
import DetailsForm from '../../components/DetailsForm';

function Cabinet(props) {
    useEffect(() => {
        if (!localStorage.getItem('about')) {
            const fadeInRight = new IntersectionObserver(entries => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add('fadeInRight');
                    }
                });
            });
            const fadeInUp = new IntersectionObserver(entries => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add('fadeInUp');
                    }
                });
            });
            fadeInRight.observe(document.querySelector('.aboutText1'));
            fadeInRight.observe(document.querySelector('.aboutText2'));
            fadeInRight.observe(document.querySelector('.aboutText3'));
            fadeInRight.observe(document.querySelector('.aboutText4'));
            fadeInRight.observe(document.querySelector('.aboutText5'));
            fadeInUp.observe(document.querySelector('.aboutImg1'));
            fadeInUp.observe(document.querySelector('.aboutImg2'));
            fadeInUp.observe(document.querySelector('.aboutImg3'));
            fadeInUp.observe(document.querySelector('.aboutImg4'));
            localStorage.setItem('about', true)
        }
    }, [])


    return (
        <div className='overflow-x-hidden'>
            {/* Home hero with separator */}
            <div className='heroContainerSmallCabinet'>
                <div className='heroTextContainerSmall'>
                    <h1 className='heroTitle md:mb-3 md:text-4xl md:mb-1 leading-tight text-3xl pb-3'>VOTRE CABINET À DÉOLS</h1>
                    <h3 className='heroSubtitle text-base md:text-xl pb-4'>
                        Une centre de santé rénové et équipé, pour toute la famille
                    </h3>

                </div>
            </div>
            {window.innerWidth > 768 &&
                <div class="custom-shape-divider-bottom-1731245909">
                    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                        <path d="M600,112.77C268.63,112.77,0,65.52,0,7.23V120H1200V7.23C1200,65.52,931.37,112.77,600,112.77Z" class="shape-fill"></path>
                    </svg>
                </div>
            }
            <div className='mt-6 mb-10 md:mb-20'>
                <AboutHero anchorScroll={anchorScroll} />
            </div>

            {/* Contact and form */}
            <div className='px-2 md:px-8 mt-10 md:mt-0'>
                <DetailsForm />
            </div>

        </div>
    )
}

export default Cabinet