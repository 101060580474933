import Navbar from './screens/Navbar/Navbar';
import { Routes, Route } from "react-router-dom";
import './App.css';
import { useEffect, useState } from 'react';
import TagManager from 'react-gtm-module'

// Screens
import Home from './screens/Home/Home';
import Footer from './screens/Footer/Footer';
import Contact from './screens/Contact/Contact';
import Legal from './components/Legal';
import NotFound from './screens/NotFound';
import Cabinet from './screens/Cabinet/Cabinet';
import Equipe from './screens/Equipe/Equipe';
import Soins from './screens/Soins/Soins';

const tagManagerArgs = {
  gtmId: 'GTM-N9JP9JC',
}

function App() {
  const [menu, setMenu] = useState(false)
  const [currentScreen, setCurrentScreen] = useState(null)

  useEffect(() => {
    setCurrentScreen(window.location.pathname)
    TagManager.initialize(tagManagerArgs)
  }, [])

  return (
    <div className="App overflow-x-hidden">
      <Navbar menu={menu} setMenu={setMenu} setCurrentScreen={setCurrentScreen} currentScreen={currentScreen} />

      <Routes>
        <Route path="/" element={<Home setCurrentScreen={setCurrentScreen} />} />
        <Route path="/cabinet" element={<Cabinet setCurrentScreen={setCurrentScreen} />} />
        <Route path="/soins" element={<Soins setCurrentScreen={setCurrentScreen} />} />
        <Route path="/equipe" element={<Equipe setCurrentScreen={setCurrentScreen} />} />
        <Route path="/contact" element={<Contact setCurrentScreen={setCurrentScreen} />} />
        <Route path="/legal" element={<Legal setCurrentScreen={setCurrentScreen} />} />
        <Route path='*' element={<NotFound />} />
      </Routes>

      <Footer />
    </div>
  );
}

export default App;