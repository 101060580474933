import React from 'react'

// Import pics
import disabled from '../../assets/disabled.svg'
import location from '../../assets/location.svg'
import pro from '../../assets/pro.svg'
import teeth from '../../assets/teeth.svg'
import radio from '../../assets/radio.svg'
import DetailsForm from '../../components/DetailsForm'
import team from '../../assets/team2.jpg'

function Contact(props) {
    return (
        <div className='overflow-x-hidden'>
            {/* Home hero with separator */}
            <div className='heroContainerSmallContact'>
                <div className='heroTextContainerSmall'>
                    <h1 className='heroTitle md:mb-3 md:text-4xl md:mb-1 leading-tight text-3xl pb-3'>NOUS CONTACTER</h1>
                    <h3 className='heroSubtitle text-base md:text-xl'>
                        Pour prendre rendez-vous ou en cas d'urgence, appelez le:
                    </h3>
                    <a href="tel:+33254351509">
                        <h1 className='heroTitle mt-1 md:text-5xl leading-tight text-3xl max-w-2xl'>02 54 35 15 09</h1>
                    </a>
                </div>
            </div>
            {window.innerWidth > 768 &&
                <div class="custom-shape-divider-bottom-1731245909">
                    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                        <path d="M600,112.77C268.63,112.77,0,65.52,0,7.23V120H1200V7.23C1200,65.52,931.37,112.77,600,112.77Z" class="shape-fill"></path>
                    </svg>
                </div>
            }

            <div className='px-4 md:px-20 mt-6 md:mt-16' >
                <div className="grid md:grid-col-2 md:gap-x-2 gap-y-6 md:gap-y-0 mx-auto lg:flex">
                    <div className="md:w-1/2 mx-auto lg:mx-0 ">
                        <div className="px-2 md:px-12 md:pb-8">
                            <h4 className='mt-6 mb-1 text-lg leading-7 font-bold'>
                                Centre de Santé Oasis
                            </h4>
                            <p className="aboutText3 text-lg leading-7 text-gray-600">
                                27 rue Ferdinand Gigot
                            </p>
                            <p className="aboutText3 text-lg leading-7 text-gray-600">
                                36130 Déols
                            </p>
                            <ul className='mt-8'>
                                <div className='flex  my-4'>
                                    <img src={disabled} className="h-6 w-6 mr-4" alt="" />
                                    <p className="aboutText3 text-base md:text-lg leading-7 text-gray-600">
                                        Accessibilité PMR
                                    </p>
                                </div>
                                <div className='flex  my-4'>
                                    <img src={location} className="h-6 w-6 mr-4" alt="" />
                                    <p className="aboutText3 text-base md:text-lg leading-7 text-gray-600">
                                        Emplacement central
                                    </p>
                                </div>
                                <div className='flex  my-4'>
                                    <img src={pro} className="h-6 w-6 mr-4" alt="" />
                                    <p className="aboutText3 text-base md:text-lg leading-7 text-gray-600">
                                        Qualité des soins
                                    </p>
                                </div>
                                <div className='flex  my-4'>
                                    <img src={teeth} className="h-6 w-6 mr-4" alt="" />
                                    <p className="aboutText3 text-base md:text-lg leading-7 text-gray-600">
                                        Technologies de pointe
                                    </p>
                                </div>
                                <div className='flex  my-4'>
                                    <img src={radio} className="h-6 w-6 mr-4" alt="" />
                                    <p className="aboutText3 text-base md:text-lg leading-7 text-gray-600">
                                        Empreintes numériques | Radio panoramique 3D
                                    </p>
                                </div>
                            </ul>
                        </div>
                    </div>
                    <img src={team} className="md:w-1/2 rounded-xl" alt="" />
                </div>
            </div>

            <div className='px-2 md:px-8 mt-14 md:mt-20'>
                <DetailsForm />
            </div>
        </div>
    )
}

export default Contact