import { Button } from "@headlessui/react";

import {
    ChevronDownIcon,
    ChevronUpIcon,
} from '@heroicons/react/20/solid'

export default function RoleTile(props) {

    return (
        <div id={props.index}>
            <div className="lg:flex px-3 md:px-0 lg:items-center lg:justify-between lg:px-8 py-4 mt-5 bg-gray-100 rounded-lg shadow-md">
                <h4 className="text-lg mb-2 md:mb-0 text-left font-bold leading-7 text-indigo-900 md:truncate md:text-2xl md:tracking-tight">
                    {props.title}
                </h4>
                <div className="md:block w-full md:w-auto h-full">
                    <Button
                        onClick={() => {
                            props.setToggleRoleDetails(props.toggleRoleDetails === props.toggleIndex ? null : props.toggleIndex)
                        }}
                        type="button"
                        className="justify-center flex w-full items-center rounded-md bg-white px-3.5 py-2.5 text-sm md:text-md font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                    >
                        {props.toggleRoleDetails === props.toggleIndex ? <ChevronUpIcon aria-hidden="true" className="-ml-0.5 h-6 w-6 text-gray-400" /> : <ChevronDownIcon aria-hidden="true" className="-ml-0.5 h-6 w-6 text-gray-400" />}
                        En savoir plus
                    </Button>
                </div>

            </div>
            {props.toggleRoleDetails === props.toggleIndex &&
                <div className="p-4 text-base">
                    {props.facts}
                </div>
            }
        </div>
    )
}
